document.addEventListener("turbolinks:load", function() {
  $('#show-status-select').select2({
    placeholder: "Filter by status",
    allowClear: true
  });

  $('#sort-by-select').select2({
    minimumResultsForSearch: -1
  });

  $('#question-type-select').select2({
    minimumResultsForSearch: Infinity
  });


  $('#question-edit-type-select').select2({
    minimumResultsForSearch: Infinity
  });


  $('#survey-keyword-select').select2({
    placeholder: "Select Keyword",
    allowClear: true
  });

  $('#survey-program-select').select2({
    placeholder: "Select Program",
    allowClear: true
  });
});

if ($('select').data('select2')) {
  $(document).on('turbolinks:before-cache', function() {
    $('#show-status-select').select2('destroy');
    $('#sort-by-select').select2('destroy');
    $("select.select2-hidden-accessible").select2('destroy');
  });
}



