// NOTE: Commented out this file because of inconsistent application of autocomplete functionality
// on _shared_survey partial; moved all of this into <script> tags on that partial, where it works more 
// consistently. Ideally that would be moved back here if we can figure out why
// it sometimes fails.

$(document).on('turbolinks:load',function(){
    let autocomplete;

    function initializeAutocomplete(id) {
      //console.log(`INIT AutoComplete ${id}`)
      var elements = [...document.getElementsByClassName(id)];
      //console.log(elements);
      if (elements.length > 0) {
        elements.forEach((element) => {
          //console.log(`Element Found`)
          autocomplete = new google.maps.places.Autocomplete(element, {
            types: ['geocode'],
          componentRestrictions: {country: 'us'}
        });
        autocomplete.setFields('address_components', 'formatted_address');
        autocomplete.addListener('place_changed', onPlaceChanged.bind(autocomplete, element));
      });
    }
  }

  function onPlaceChanged(element) {
    var place = this.getPlace();
    let addressFields = [...$(element).siblings('.address-field')];

    //console.log(addressFields);
    addressFields.forEach((field) => field.value = "")
    //console.log(place);  // Uncomment this line to view the full object returned by Google API.     

    for (const component of place.address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        case "street_number": {
          addressFields[0].value = component.long_name
          break;
        }
        case "route": {
          addressFields[0].value += ` ${component.short_name}`;
          break;
        }
        case "postal_code": {
          addressFields[3].value = `${component.long_name}${addressFields[3].value}`;
          break;
        }
        case "postal_code_suffix": {
          addressFields[3].value = `${addressFields[3].value}-${component.long_name}`;
          break;
        }
        case "locality": {
          addressFields[1].value = component.long_name;
          break;
        }
        case "neighborhood": {
          addressFields[1].value = component.long_name;
          break;
        }
        case "sublocality_level_1": {
        addressFields[1].value = component.long_name;
        break;
      }
        case "administrative_area_level_1": {
          addressFields[2].value = component.short_name;
          break;
        }
        case "country": {
          addressFields[4].value = component.long_name;
          break;
        }
      }
    }
  }

  if (typeof google !== 'undefined') {
    initializeAutocomplete('autocomplete_address');
  }
})
