$(document).ready(function(){

    $("#organization_logo").change(function(){

        var image = window.URL.createObjectURL(this.files[0]);

        $("#broken-image").attr("src", image);

    });

});