document.addEventListener("turbolinks:load", function() {

  // JQuery function to dynamically update the options for selectable phone codes on create/edit sub organization
  $('#primary-org-select').change((e) => {
    const { value } = e.target;
    $.ajax({
      url: `/organizations/${value}/phone_codes`,
      dataType: "json",
      type: "GET",
      error: (xhr, status, error) => {
        console.error(`AJAX Error: ${error}`);
      },
      success: (data) => {
        const element = $('#organization-phone-codes-select');
        element.children().remove();

        const formatNumber = (str) => {
          let cleaned = ('' + str).replace(/\D/g, '');
          let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
          let intlCode = (match[1] ? '+1 ' : '')
          return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
        }

        element.append(
          data.map(e => '<option value="' + e.id + '">' + formatNumber(`${e.number}`) + '</option>')
        );
      }
    })
  });
});

