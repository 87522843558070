document.addEventListener("turbolinks:load", function() {
  $('#keyword-phonecode-select').select2({
  });

  $('#keyword-phonecode-select').one('select2:open', function(e) {
    $('input.select2-search__field').prop('placeholder', 'Search');
  });
});

if ($('select').data('select2')) {
  $(document).on('turbolinks:before-cache', function() {
    $('#keyword-phonecode-select').select2('destroy');
  });
}