document.addEventListener("turbolinks:load", function() {

  $('#process-upload-btn').hide();

  var $drop = $("#clientUploadDropzone").dropzone({
    previewTemplate: document.querySelector("#uploadPreviewTemplate").innerHTML,
    acceptedFiles: '.csv', 
    error: function(file, errorMessage) {
      // console.log("errorMessage:", errorMessage);
      // console.log("errorMessage.errors:", errorMessage.errors);
      var message;
      if (typeof errorMessage.errors != 'undefined') {
        message = errorMessage.errors;
      } else {
        message = errorMessage;
      }

      $drop.find('#notice-container').addClass('alert alert-danger').text(message);
      this.removeFile(file);
    }, 
    success: function(file, response){

      var uploadId = response.upload_id

      $('#process-upload-btn').show();
      $('#process-upload-btn').data('upload-id', uploadId);
      $drop.find('#notice-container').removeClass('alert alert-danger').text('');
    }
  });

  $('#process-upload-btn').click(function() {
    var $uploadBtn = $(this);
    var uploadId = $uploadBtn.data('upload-id');
    var uploadLink = $uploadBtn.data('upload-link');
    var processUploadLink = uploadLink + '?upload_id=' + uploadId

    window.location = processUploadLink;
  });

});
