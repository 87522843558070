document.addEventListener("turbolinks:load", function() {
  function optin_preview() {
    var add_on_opt_in   = "For help reply HELP. MsgDataRatesMayApply. Reply STOP to opt out";
    var preview_element = document.getElementById("message_preview_opt_in");
    var opt_in_text     = document.getElementById("keyword_opt_in_message").value;
    var keyword_name    = document.getElementById("keyword_name").value;
    var preview_text    = "";

    if (!opt_in_text) {
        preview_text = [keyword_name.toUpperCase(), add_on_opt_in].join(" ");
    }else{
        preview_text = [keyword_name.toUpperCase(), opt_in_text, add_on_opt_in].join(" ");
    }

    preview_element.innerHTML = preview_text
  }

  function help_preview() {
    var add_on_opt_in   = ""; //TODO Please email your help request to this email: Mobilize email goes here.
    var preview_element = document.getElementById("message_preview_help");
    var help_text       = document.getElementById("keyword_help_message").value;
    var keyword_name    = document.getElementById("keyword_name").value;
    var preview_text    = [keyword_name.toUpperCase(), help_text].join(" ")

    if (!help_text) {
        preview_text = [keyword_name.toUpperCase(), add_on_opt_in].join(" ");
    }else{
        preview_text = [keyword_name.toUpperCase(), help_text].join(" ");
    }

    preview_element.innerHTML = preview_text
  }

  $("#keyword_opt_in_message").keyup(function(){
    optin_preview();
    help_preview();
  });

  $("#keyword_help_message").keyup(function(){
    optin_preview();
    help_preview();
  });

  $("#keyword_name").keyup(function(){
    optin_preview();
    help_preview();
  });

  window.onload = function() {
    optin_preview();
    help_preview();
  };
});