document.addEventListener("turbolinks:load", function() {
  $(".calendar-day").click(function() {
    $(".blast-date-prompt").remove();
    let date = $(this).data("date");
    let splitDate = date.split("-")
    let formattedDate = `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`
    $("#blasts-by-date").children().hide();
    $(`#blasts-for-${ date }`).show();
    $(`#blast-date-target`)
      .after(`<p id="blast-show-date" class="blast-date-prompt text-center">Showing blasts for ${ formattedDate }</p>`);
    $("#reset").show();
  });

  $("#reset").click(function() {
    $("#blasts-by-date").children().show();
    $("#reset").hide();
    $("#blast-show-date").remove();
  });

  $("#date-select").change(function() {
    this.form.submit();
  });

  $("#date-select").keypress(function(event) {
    $(this).off("change blur");

    $(this).blur(function() {
      if ($(this).val()) {
        this.form.submit();
      }
    });

    if (event.keyCode === 13 && $(this).val()) {
      this.form.submit();
    }
  });
});


