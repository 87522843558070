document.addEventListener("turbolinks:load", function() {
  $("#questions-list").sortable({
    update: function(e, ui) {
      $.ajax({
        url: $(this).data("url"),
        type: 'PATCH',
        data: $(this).sortable('serialize')
      })
    }
  })
  $(document).ready (function(){
    $("#questions-list").sortable({
      update: function(e, ui) {
        $.ajax({
          url: $(this).data("url"),
          type: 'PATCH',
          data: $(this).sortable('serialize')
        })
      }
    })
  })
});