document.addEventListener("turbolinks:load", function() {
    $('#organization_switch_select').select2({
        minimumResultsForSearch: -1,
        dropdownParent: $('#dropdown_parent')
    });
});

if ($('select').data('select2')) {
    $(document).on('turbolinks:before-cache', function() {
        $('#organization_switch_select').select2('destroy');
    });
}