document.addEventListener("turbolinks:load", function() {
  $('#primary-org-select').select2({
  });

  $('#organization-phone-codes-select').select2({
    placeholder: "Search phone numbers",
  });

  $('#primary-org-select').one('select2:open', function (e) {
    $('input.select2-search__field').prop('placeholder', 'Search organizations');
  });
});

if ($('select').data('select2')) {
  $(document).on('turbolinks:before-cache', function() {
    $('#primary-org-select').select2('destroy');
    $('#organization-phone-codes-select').select2('destroy');
  });
}
