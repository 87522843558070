import tippy from 'tippy.js';

document.addEventListener("turbolinks:load", function() {
  tippy('#left-nav-dashboard', {
    content: 'Dashboard',
    trigger: 'mouseenter focus',
    arrow: true,
    placement: 'right',
  });
  tippy('#left-nav-clients', {
    content: 'Clients',
    trigger: 'mouseenter focus',
    arrow: true,
    placement: 'right',
  });
  tippy('#left-nav-surveys', {
    content: 'Surveys',
    trigger: 'mouseenter focus',
    arrow: true,
    placement: 'right',
  });
  tippy('#left-nav-communications', {
    content: 'Communications',
    trigger: 'mouseenter focus',
    arrow: true,
    placement: 'right',
  });
  tippy('#left-nav-organization', {
    content: 'Organization',
    trigger: 'mouseenter focus',
    arrow: true,
    placement: 'right',
  });
});