document.addEventListener("turbolinks:load", function() {

  $('#blast_summary_report_month').select2({
    placeholder: "Select month",
  });

  $('#blast_summary_report_year').select2({
    placeholder: "Select year",
  });

  $('#blast_summary_report_month').one('select2:open', function(e) {
    $('input.select2-search__field').prop('placeholder', 'Search');
  });

  $('#blast_summary_report_year').one('select2:open', function(e) {
    $('input.select2-search__field').prop('placeholder', 'Search');
  });


  $('#repeat-select').select2({
    placeholder: "Select how often",
  });

  $('#repeat-select').one('select2:open', function(e) {
    $('input.select2-search__field').prop('placeholder', 'Search');
  });


  $('#blast-number-select').select2({
    placeholder: "Select a phone number",
  });

  $('#blast-keyword-select').select2({
    placeholder: "Select a keyword",
  });

  $('#blast-clients-select').select2({
    placeholder: "Select Client(s)",
    allowClear: true
  });

  $('#blast-group-select').select2({
    placeholder: "Select Groups(s)",
    allowClear: true
  });
});

if ($('select').data('select2')) {
  $(document).on('turbolinks:before-cache', function() {
    $('#blast-group-select').select2('destroy');
    $('#blast-clients-select').select2('destroy');
    $('#blast-keyword-select').select2('destroy');
    $('#blast-number-select').select2('destroy');
    $('#repeat-select').select2('destroy');
  });
}

