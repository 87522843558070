// calculate_rate function takes in the id and element to get the value from element
// apply an API call using the value, capture the response and apply the response on the
// element of provided id.
function calculate_rate(id, el) {
    console.log("Text Change");
    console.log(`ID: ${id}`);
    console.log(`This: ${el.value}`);
    $.ajax({
        url: "/message_check",
        type: "GET",
        dataType: "json",
        data: {
            "message" : el.value,
        },
        error: function (xhr, status, error) {
            console.error(`AJAX Error: ${error}`);
        },
        success: function (response) {
            console.log(response);
            document.getElementById(id).innerHTML = `${response["rate"]}x`;
        }
    });
};

// showCompMsg makes the element of id confirmation_message_form_group to display:block
function showCompMsg() {
    compText = document.getElementById('confirmation_message_form_group');
    compText.style.display = "block";
}

// hideCompMsg makes the element of id confirmation_message_form_group to display:none
function hideCompMsg() {
    compText = document.getElementById('confirmation_message_form_group');
    compText.style.display = "none";
}

// toggleCompMsg understands the logic of checkbox and show/hide accordingly.
function toggleCompMsg() {
  if (document.getElementById('survey_completion_message_confirmation').checked === true) {
    showCompMsg()
  } else {
    hideCompMsg()
  }
}

function computeRates() {
  var surveyForm = document.getElementById('survey-form');
  if (surveyForm != null) {
      toggleCompMsg()
      calculate_rate('start-rate', document.getElementById('survey_start_message'))
      calculate_rate('start-rate-cmsg', document.getElementById('survey_submit_button_text_area_trix_input_survey'))

      document.getElementById('survey_completion_message_confirmation').addEventListener("click", function(event) {
          toggleCompMsg()
      })

      document.getElementById('survey_start_message').addEventListener("keyup", function(event) {
          calculate_rate('start-rate', document.getElementById('survey_start_message'))
      })

      document.getElementById('survey_completion_message').addEventListener("keyup", function(event) {
          calculate_rate('start-rate-cmsg', document.getElementById('survey_completion_message'))
      })
  }
}

function poop() {
    console.log("----><----");
}

function toggleSurveyManagement(id) {
    var element = document.getElementById(id);
    if (element.classList.contains("show")) {
        element.classList.remove("show");
    }else{
        element.classList.add("show");
    }
}


$(document).ready(function() {
  computeRates()

    document.getElementById('survey-card').addEventListener("click", e => {
        console.log("CLICKED");
        var survey_id =   document.getElementById("survey-card").innerHTML = a[0].getAttribute("value");
	if (surveyCard == null) { return }
        toggleSurveyManagement(id)
    });
})


$(document).on('turbolinks:load', function(){
    // On load we will hide/show the completion_message by the logic of survey_completion_message_confirmation checkbox.
    // Also, on load, during the edit pages of survey, the start-rate and start-rate-cmsg are filled with proper value ex. 1x 2x 3x etc
    computeRates()
})
