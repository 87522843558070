// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
window.jQuery = $;
window.$ = $;
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import './clients'

import "../stylesheets/application"


require("chartkick")
require("chart.js")


Rails.start()
Turbolinks.start()
ActiveStorage.start()


import '@nathanvda/cocoon'
import "@rails/actiontext"
import 'trix'
import 'bootstrap'
import 'bootstrap-maxlength'
import "controllers"
import "dropzone"
import 'popper.js'
import "node-waves"
import 'select2'
import 'select2/dist/css/select2.css'
import 'tippy.js/dist/tippy.css'; // optional for styling
import 'flatpickr'
import 'summernote'

// shared
import '../shared/shared';

// questions
import "jquery-ui-bundle";
import '../questions/sorting';

// tooltips
import '../tooltips/leftNav'
import '../tooltips/clientsIndex'


// Action text JS
import '../actionText/fileUpload';

import './clients'
import './client_uploads'
import '../actionText/overrides'

// Blasts Create JS
import '../blasts/blastCreate';
import '../blasts/blastCalendar';

// Clients JS
import '../packs/clients';

// Keywords JS
import '../keywords/keywordsCreate';

// Surveys JS
import '../surveys/surveyCreate';

import '../surveys/sharedSurvey.js';

// Roles JS
// import '../roles/form.js.erb';

// The remains of the theme...
import '../themeJS/themeApp'

// Select2 JS
// import '../select2Views/blastsSelect2';


import '../select2Views/blastsSelect2';
import '../select2Views/groupSelect2';
import '../select2Views/keywordSelect2';
import '../select2Views/rolesSelect2';
import '../select2Views/subOrgsSelect2';
import '../select2Views/surveysSelect2';
import '../select2Views/topmenuSelect2';
import '../select2Views/paginationSelect2';

// NestedForm
import '../nestedForm/addClientAddressFields';
import '../nestedForm/addClientEmailFields';
import '../nestedForm/addClientPhoneNumberFields';
import '../nestedForm/addFields';

import '../nestedForm/removeClientAddressFields';
import '../nestedForm/removeClientEmailFields';
import '../nestedForm/removeClientPhoneNumberFields';
import '../nestedForm/removeFields';

// Sub Organizations
import '../sub_organizations';

// Organizations
import '../organizations/form';

// Autocomplete JS
import '../packs/autocomplete';
