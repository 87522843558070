var addContactItemRow = function($addContactItemBtn) {
  // console.log("in addContactItemRow");
  var $contactItemContainer = $addContactItemBtn.closest('.contact-item-container');
  // console.log("$contactItemContainer:", $contactItemContainer);
  var $contactItemForm = $contactItemContainer.find('.contact-item-form');
  // var $contactItemTable = $contactItemContainer.find('table');
  var $lastContactItemRow = $contactItemContainer.find('.contact-item-row-fieldset:visible').last();
  // console.log("$lastContactItemRow:", $lastContactItemRow);
  var rowCount = $contactItemContainer.find('.contact-item-row-fieldset').length; 
  // ^^^ counts hidden "deleted" rows as well (or should) b/c they're still present and have ids that need to be unique
  // console.log("rowCount:", rowCount);
  var shouldIncrementIdxAttrs;

  if (rowCount == 1) { // if there's only one contact item row
    // don't think this 'main-field' idea is a good solution
    var $mainField = $lastContactItemRow.find('input.main-field');

    if ($mainField.val() == '') {
      var $newContactItemRow = $lastContactItemRow // if it's just one empty row, use that instead of cloning
      shouldIncrementIdxAttrs = false
    // if ($lastContactItemRow.find('input.id-field').length > 0) {
    } else {
      var $newContactItemRow = $lastContactItemRow.clone();
      shouldIncrementIdxAttrs = true
    }
  } else {
    // console.log("inside else (meaning rowCount does not equal 1)");
    var $newContactItemRow = $lastContactItemRow.clone();
    shouldIncrementIdxAttrs = true
  }

  $newContactItemRow.find('input').val(''); // clear values if it was cloned obvs

//////// CLEAR OUT UPPER FORM
  $contactItemForm.find('input').each(function() {
    var $input = $(this);
    var fieldName = $input.data('field-name');
    var fieldValue = $input.val();
    // then clear these inputs and the actual visible fields when applicable
    var $contactTd = $newContactItemRow.find('td.' + fieldName);
    $contactTd.text(fieldValue); // consolidate into 1 row and remove variable assign if I don't end up doing more w/this

    var $contactInput = $newContactItemRow.find('input.' + fieldName); // same, maybe consolidate into 1 row
    $contactInput.val(fieldValue);

    if (fieldName.indexOf('primary') > -1) {
      $input.val('false'); // so the primary select defaults to false
    } else {
      $input.val(''); // will also clear the dropdowns' assoc. hidden fields
    }
  });

  if (shouldIncrementIdxAttrs === true) {
    incrementIdxAttrs($newContactItemRow, $contactItemContainer);
  }

  removePersistedRecordFields($newContactItemRow);
  $newContactItemRow.insertAfter($lastContactItemRow);
  // manageFirstRowRemovalLink();
  bindRemovalLinks();
}

var removePersistedRecordFields = function($newContactItemRow) {
  $newContactItemRow.find('.id-field').remove();
  $newContactItemRow.find('.removal-field').remove();
}

var bindRemovalLinks = function() {
  $('.remove-contact-item-row').unbind('click').on('click', function() {
    var $fieldset = $(this).closest('.contact-item-row-fieldset');
    var $removalLink = $(this);

    manageRemovalLinks($fieldset, $removalLink);
  });
}

var manageRemovalLinks = function($fieldset, $removalLink) {
  event.preventDefault();

  var $confirmLinksDiv = $fieldset.find('.remove-confirm');
  var $confirmLinks = $confirmLinksDiv.find('.remove-contact-item-row');

  $removalLink.hide();
  $confirmLinksDiv.css('display', 'inline');

  $confirmLinks.unbind('click').on('click', function() {
    event.preventDefault();
    var $confirmLink = $(this);

    if ($confirmLink.hasClass('yes')) {
      removeContactItemRow($confirmLink);
    } else if ($confirmLink.hasClass('no')) {
      $confirmLink.closest('.remove-confirm').hide();
      $removalLink.show();      
    }
  });
}

var removeContactItemRow = function($confirmLink) {
  var $contactItemRow = $confirmLink.closest('.contact-item-row-fieldset');
  var $removalField = $contactItemRow.find('.removal-field');
  // cloneContactItemRow($contactItemRow);
  // hideOrRemove($removalField, $contactItemRow);
  var $contactItemContainer = $confirmLink.closest('.contact-item-container');
  var isPrimary = $contactItemRow.find('input.primary').val();
  // console.log("isPrimary:", isPrimary);
  var rowCount = $contactItemContainer.find('.contact-item-row-fieldset').length;
  // ^^^ always need to find within the container when doing a count, otherwise it will count rows for other contact types
  // console.log("rowCount:", rowCount);

  if (rowCount == 1) {
    // console.log("in if (rowCount == 1)");
    var $newContactItemRow = $contactItemRow.clone();
    // $newContactItemRow.insertAfter($contactItemRow);
    $newContactItemRow.find('input').val('');
    $newContactItemRow.find('td.input-display').text('');

    if ($removalField.length != 0) {
      $newContactItemRow.find('.persisted-field').each(function() { // can probs remove iterator?
        $(this).remove(); // need to remove id and removal fields for cloned row b/c it's for a new record only
      });
    }

    incrementIdxAttrs($newContactItemRow, $contactItemContainer);
    $newContactItemRow.find('.remove-confirm').hide();
    $newContactItemRow.insertAfter($contactItemRow);
    bindRemovalLinks();
  }

  if ($removalField.length != 0) { // if the removalField is present, and thus, whether it's an edit form or not
    $removalField.val('true');     // (b/c removalField only shows up for persisted record).  Then it's flagged for
    $contactItemRow.hide();              // removal and hidden, otherwise it's just removed. (note from 11/24/2020)
  } else {
    $contactItemRow.remove();
  }

  if (isPrimary == "true") { // if the removed one was a prmary one, enable primary dropdown in case it was disableld earlier
    // console.log("should be removing disabled class");
    var $dropdown = $contactItemContainer.find('.dropdown');
    $dropdown.find('a.contact-dropdown-option').removeClass('disabled');
  }

}

var incrementIdxAttrs = function($newContactItemRow, $contactItemContainer) {
  // console.log("$newContactItemRow:", $newContactItemRow);
  // console.log("$contactItemContainer:", $contactItemContainer);
  $newContactItemRow.find('.remove-contact-item-row').show();
  var contactItemRowCount = $contactItemContainer.find('.contact-item-row-fieldset').length;

  var oldId = $newContactItemRow.prop('id');
  var newId = oldId.replace(new RegExp(/[0-9]/), contactItemRowCount); // this might cause there to be 2 w/idx of 1 on create
  $newContactItemRow.prop('id', newId);

  $newContactItemRow.find('input').each(function() {
    var oldId = $(this).attr('id');
    var newId = oldId.replace(new RegExp(/_[0-9]+_/), "_" + contactItemRowCount + "_");
    $(this).attr('id', newId);

    var oldName = $(this).attr('name');
    var newName = oldName.replace(new RegExp(/\[[0-9]+\]/), "[" + contactItemRowCount + "]");
    $(this).attr('name', newName); 
  });
}

// $(document).ready(function () {
document.addEventListener("turbolinks:load", function() {

  // console.log("clients.js is ready!!")

  $('#client_phone_numbers_table').find('tr').each(function() {
    var $this = $(this);
    $this.find('td').each(function() {

      var spanText = $(this).find('span').text();
      // console.log("spanText:", spanText);
      $(this).find('span').text('lol');
    });

  });

  $('.contact-dropdown-option').on('click', function(e) {
    e.preventDefault();

    var $this = $(this);
    var selected = $this.data('value');
    // console.log("selected:", selected);
    var $container = $this.closest('div.dropdown');

    $container.find('input').val(selected);
  });

  $('.add-contact-item-btn').on('click', function(e) {
    e.preventDefault();
    var $addContactItemBtn = $(this);

    addContactItemRow($addContactItemBtn);
  });

  $('.btn.primary-dropdown').on('click', function(e) {
    var $this = $(this);
    var $dropdown = $this.closest('.dropdown');
    var $contactItemContainer = $this.closest('.contact-item-container');

    var hasPrimaryAlready = false;
    var removedRow = false;

    $contactItemContainer.find('input.primary').each(function() {
      var $this = $(this);
      var $contactItemRow = $this.closest('.contact-item-row-fieldset');
      var $removalField = $contactItemRow.find('.removal-field');

      if ($removalField.length) {
        if ($removalField.val() == "true") { removedRow = true }
      } // should I just use strings instead to avoid these stupid "true" -> true conversions?

      if ($this.val() == "true") { hasPrimaryAlready = true }
    });

    // console.log("removedRow:", removedRow);
    // console.log("hasPrimaryAlready:", hasPrimaryAlready);
    // console.log("(hasPrimaryAlready === true):", (hasPrimaryAlready === true));
    // console.log("(removedRow === false):", (removedRow === false));
    // console.log("((hasPrimaryAlready === true) && (removedRow === false)):", ((hasPrimaryAlready === true) && (removedRow === false)));
    // console.log("$dropdown.find('a.contact-dropdown-option').text():", $dropdown.find('a.contact-dropdown-option').text());

    if ((hasPrimaryAlready === true) && (removedRow === false)) {
      $dropdown.find('a.contact-dropdown-option').addClass('disabled');//.prop('disabled', true);
      // show tooltip
    }

  });

  // getLastIdx();
  // manageFirstRowRemovalLink();
  bindRemovalLinks();
});

