import tippy from 'tippy.js';

document.addEventListener("turbolinks:load", function() {
  tippy('#client-export-button', {
    content: 'Export data for clients created in the specified date range',
    trigger: 'mouseenter focus',
    arrow: true,
    placement: 'top',
  });
});
