document.addEventListener("turbolinks:load", function() {
    function formatAddress(addressComponents) {
        let streetNumber = '';
        let route = '';
        let city = '';
        let state = '';
        let postalCode = '';
        let country = '';

        addressComponents.forEach(component => {
            const types = component.types;
            if (types.includes('street_number')) {
                streetNumber = component.long_name;
            }
            if (types.includes('route')) {
                route = component.long_name;
            }
            if (types.includes('locality') || types.includes('neighborhood')) {
                city = component.long_name;
            }
            if (types.includes('administrative_area_level_1')) {
                state = component.short_name;
            }
            if (types.includes('postal_code')) {
                postalCode = component.long_name;
            }
            if (types.includes('country')) {
                country = component.long_name;
            }
        });

        // Combine into a full address string and remove any extra commas/spaces
        return `${streetNumber} ${route}, ${city}, ${state} ${postalCode}, ${country}`.trim();
    }

    // Event listener for when a place is selected
    document.querySelector('.autocomplete_address').addEventListener('change', function() {
        const thisInput = this;

        setTimeout(function() {
            const requiredFields = [...document.getElementsByClassName('required-field')];
            let addressComponents = thisInput.getAttribute('data-address-components'); // assuming this data is available

            if (!addressComponents) {
                console.error("No address components found.");
                return;
            }

            addressComponents = JSON.parse(addressComponents);
            const formattedAddress = formatAddress(addressComponents);

            thisInput.value = formattedAddress;

            // Validate required fields
            const missingFields = requiredFields.some(field => field.value === "");
            if (missingFields) {
                thisInput.style.borderColor = "red";
                document.querySelector('.missing-address-fields').textContent = 
                    "Please ensure Street, City, State, Postcode, and Country are filled out.";
                document.getElementById('survey-submit-btn').disabled = true;
            } else {
                thisInput.style.borderColor = "";
                document.querySelector('.missing-address-fields').textContent = "";
                document.getElementById('survey-submit-btn').disabled = false;
            }
        }, 500);
    });
});
