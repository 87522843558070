document.addEventListener("turbolinks:load", function() {


  function updateMessageMax() {

    var keyword_text = document.getElementById("select2-blast-keyword-select-container").title;
    var review_text = $("#blast_message");
    var new_message_max = 1000;
    var counter     = $("#counter");

    if (keyword_text.length > 0) {
        new_message_max = new_message_max - (keyword_text.length + 1) - review_text[0].value.length
    }else{
        new_message_max = new_message_max - review_text[0].value.length
    }
    review_text.prop('maxLength', 1000 - (keyword_text.length + 1));
    counter.text(new_message_max);

  };

  function message_preview() {
    var preview_element = document.getElementById("message_preview");

    var keyword_text = document.getElementById("select2-blast-keyword-select-container").title;

    var message_text = document.getElementById("blast_message").value;

    var preview_text = [keyword_text.toUpperCase(), message_text].join(" ");

    preview_element.innerHTML = preview_text;

    updateMessageMax();
  };

  function messageCreditAmount(credit) {
    var credit_notification =  document.getElementById("credit_notification");
    if (credit == 1) {
        credit_notification.style.display = "none";
    } else {
        credit_notification.style.display = "block";
        var message = `Your message has gone above the standard set of characters and will be charged at ${credit}x credits. Once you complete your message, please check the preview and credit count for your blast before sending.`
        $("#credit_message").text(message);
    }
    $("#rating").text(`${credit}x`)
  };

  function determineCountAndCost() {
    message_preview();
    var mms = false;
    if (document.getElementById("mms") != null && document.getElementById("mms").value != '') {
        mms = true;
    }
    var data = {
            "groups_js" : $("#blast-group-select").val(),
            "clients_js" : $("#blast-clients-select").val(),
            "message" : $("#blast_message").val(),
            "mms" : mms,
            "keyword" : $("#blast-keyword-select").val(),
            "blast_id": window.location.href.split('/')[4]
        }
    $.ajax({
        type: "GET",
        url: "/blasts/new_review",
        dataType: "json",
        data: data,
        error: function (xhr, status, error) {
            console.error(`AJAX Error: ${error}`);
        },
        success: function (response) {
            messageCreditAmount(response["sms_check"]);
                $("#msg_counter").text(`${response["msg_count"]} Credits`);
            if (response["contact_count"] > 0) {
                $("#total-contacts").text(response["contact_count"]);
            }else{
                $("#total-contacts").text(0);
            }
        }
    });
  };

  function readURL(input) {
    if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function(e) {
        $('#image_preview').attr('src', e.target.result);
        }

        reader.readAsDataURL(input.files[0]); // convert to base64 string
    }
  }

  function checkSendDate() {
      if ($('#immediate_send').prop('checked')){
          document.getElementById("send_date_time").style.display = "none";
      }else if ($('#immediate_send').prop('unchecked')){
          document.getElementById("send_date_time").style.display = "block";
      }
  }

  $(function() {
      checkSendDate();

      if ($('.custom-file-input').value != null)  {
          document.getElementById('image_preview_container').style.display = "block";
          let fileName = $('.custom-file-input').val().split('\\').pop();
          $('.custom-file-input').next('.custom-file-label').addClass("selected").html(fileName);
          readURL(this);
          console.log("Custom File Input")
      }else if (document.getElementById("mms") != null){
          console.log("NOT Custom File Input")
          document.getElementById("mms").value = null;
      };

      $('.custom-file-input').on('change', function() {
          const size =  (this.files[0].size / 1024 / 1024).toFixed(2);
          if (size > 1) {
              //alert("File must be 1MB or smaller.");
              swal({
                  title: "File Too Large",
                  text: "Your image must be 1MB or smaller.",
              });

          }else{
              document.getElementById('image_preview_container').style.display = "block";
              let fileName = $(this).val().split('\\').pop();
              $(this).next('.custom-file-label').addClass("selected").html(fileName);
              readURL(this);
              determineCountAndCost();
          }
      });

      $('#clear-attachment').on('click', function() {
          document.getElementById('image_preview_container').style.display = "none";
          determineCountAndCost();
      });


      $("#datetime-datepicker").flatpickr(
          {
              enableTime:!0,
              dateFormat:"Y-m-d H:i"
          });

      $("#blast-group-select").change(function(){
            determineCountAndCost();
        });

    $("#select2-blast-keyword-select-container").change(function(){
        determineCountAndCost();
        message_preview();
    });

    $("#blast-clients-select").change(function(){
        determineCountAndCost();
    });

    $("#blast_message").keyup(function(){
        determineCountAndCost();
        message_preview();
    });

    $("#mms").change(function(){
        determineCountAndCost();
        message_preview();
    });

      $('#immediate_send').change(function(){
          if ($(this).prop('checked')){
              document.getElementById("send_date_time").style.display = "none";
          }else{
              document.getElementById("send_date_time").style.display = "block";
          }
      });
  });


    $("#submit_button").prop('disabled', true)
    $('#submit_checkbox').bind( "change",function(){
        if ($(this).prop('checked')){
            $("#submit_button").prop('disabled', false);
        }else{
            $("#submit_button").prop('disabled', true);
        }
    })

  $(document).ready (function() {
    $('#clear-attachment').bind( "click",function(){
        $('#mms').val('');
        $('.custom-file-label').html('');
        determineCountAndCost();
    })
  });

});