document.addEventListener("turbolinks:load", function() {
    $('#pagination_page_select').select2({
        minimumResultsForSearch: -1,
        width: 'resolve'
    });
});

if ($('select').data('select2')) {
    $(document).on('turbolinks:before-cache', function() {
        $('#pagination_page_select').select2('destroy');
    });
}
