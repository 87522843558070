document.addEventListener("turbolinks:load", function() {
  $('#parent-group-add').select2({
    placeholder: 'Select a parent group'
  });

  $('#group-report').select2({
  });

  $('#keyword-group-add').select2({
    placeholder: 'Select keywords'
  });
});

if ($('select').data('select2')) {
  $(document).on('turbolinks:before-cache', function() {
    $('#parent-group-add').select2('destroy');
    $('#group-report').select2('destroy');
  });
}
